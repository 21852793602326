<template>
  <div class="container mt-2">
    <b-row>
      <b-col md="3" />
      <b-col md="6">
        <b-overlay
          :show="ShowRound"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>

          <div v-if="SubItems.length <= 0">
            <div class="backg">
              <h4 style="margin-top: 5px">
                <router-link :to="{ name: 'home' }">
                  <i class="fas fa-chevron-left fa-lg" />
                  <span style="color: #000"> หน้าหลัก</span>
                </router-link>
              </h4>
            </div>
            <!-- แทงหวย -->
            <div
              v-for="item in Items"
              :key="item._id"
              class="mb-1"
            >
              <div class="depo">
                <h3 style="color: #d61c56">
                  {{ item[0].LottoGroupName }}
                </h3>

                <b-row>
                  <b-col
                    v-for="LottoType in item"
                    :key="LottoType._id"
                    md="6"
                    lg="6"
                    cols="12"
                    style="padding: 0px 5px 10px 5px"
                  >
                    <div
                      :class="`boxhuay lotto-card cursor-pointer ${
                        LottoType.ShowCloseDate ||
                        (LottoType.LottoSubType === 1 && LottoType.LottoSubHead
                          ? LottoType.LottoSubHead.some((el) => el.ShowCloseDate)
                          : false)
                          ? ''
                          : 'disabled'
                      }`"
                      @click="RoutTang(LottoType)"
                    >
                      <div class="huay-card-header huay-card-bg-yk">
                        <img
                          class="huay-card-icon"
                          src="https://www.jack-lotto.com/assets/image/square_icon/YK.png"
                        >
                        <span
                          class="huay-card-product-yk ml-2"
                        >
                          <span class="huay-card-product-name headtxt">
                            {{ LottoType.LottoName }}
                          </span>
                          <span
                            v-if="LottoType.CloseDateName"
                            class="huay-card-period"
                          >
                            {{ LottoType.CloseDateName }}
                          </span>
                        </span>
                      </div>
                      <div class="huay-card-body">
                        <div class="huay-card-body-content">
                          <div class="lotto-time time-government">
                            <span />
                            <span
                              v-if="LottoType.ShowCloseDate"
                              class="countdown text-success"
                            >
                              <i class="fas fa-clock" />
                              {{ LottoType.ShowCloseDate }}
                            </span>
                            <span
                              v-else-if="(LottoType.LottoSubType === 1 && LottoType.LottoSubHead
                                ? LottoType.LottoSubHead.some((el) => el.ShowCloseDate)
                                : false)"
                              class="countdown text-success "
                            >
                              <i
                                aria-hidden="true"
                                class="fas fa-chevron-circle-right"
                              />
                            </span>
                            <span
                              v-else
                              class="countdown text-success "
                            >
                              <i
                                aria-hidden="true"
                                class="far fa-calendar-times"
                              />ปิดรับเเทง
                            </span>
                          </div>
                        </div>

                        <div class="d-flex flex-row justify-content-between mt-1">
                          <span
                            class="badge badge-primary"
                            style="background: #262262"
                          >
                            กติกาการเล่น </span><span
                              class="badge badge-dark"
                              style="background: #58595b"
                            >อัตราจ่าย</span>
                          <span
                            v-if="LottoType.CloseBet"
                            class="badge badge-dark"
                            style="background: #ba1e2d"
                          >
                            ปิดรับ {{ LottoType.CloseBet }}
                          </span>
                          <span
                            v-else-if="(LottoType.LottoSubType === 1 && LottoType.LottoSubHead
                              ? LottoType.LottoSubHead.some((el) => el.ShowCloseDate)
                              : false)"
                            class="badge badge-dark"
                            style="background: #ba1e2d"
                          >
                            รอบเพิ่มเติม
                          </span>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>

              </div>
            </div>
          </div>

          <!-- หวยย่อยยย -->
          <div v-if="SubItems.length > 0">
            <div @click="SubItems=[]">
              <h4 class="text-danger cursor-pointer">
                <i class="fas fa-chevron-circle-left fa-lg" />
                ย้อนกลับ
              </h4>
            </div>

            <div class="lotto">
              {{ SubItems[0].LottoHeadName }}
            </div>

            <div class="depo">
              <b-row>
                <b-col
                  v-for="LottoSubType in SubItems"
                  :key="LottoSubType._id"
                  lg="3"
                  md="4"
                  cols="6"
                  class="colum mb-1"
                >
                  <div
                    :class="`boxhuay lotto-card cursor-pointer ${
                      LottoSubType.ShowCloseDate ? '': 'disabled'
                    }`"
                    @click="RoutTang(LottoSubType)"
                  >
                    <div class="head-huay goldpattern2 align-items-stretch">
                      <div class="headtxt flex-fill">
                        <div class="pt-1 pr-1">
                          {{ LottoSubType.LottoName }}
                        </div>
                        <div
                          v-if="LottoSubType.CloseDateName"
                          class="date-huay"
                        >
                          {{ LottoSubType.CloseDateName }}
                        </div>
                        <div
                          v-if="LottoSubType.CloseBet"
                          class="subhead-huay"
                        >
                          ปิดรับ {{ LottoSubType.CloseBet }}
                        </div>
                      </div>
                    </div>
                    <div class="body-huay">
                      <span
                        v-if="LottoSubType.ShowCloseDate"
                        class="text-light"
                      ><i class="far fa-clock" />
                        {{ LottoSubType.ShowCloseDate }}</span>
                      <span
                        v-else
                        class="text-secondary"
                      ><i
                        aria-hidden="true"
                        class="far fa-calendar-times"
                      />ปิดรับเเทง
                      </span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>

        </b-overlay>
      </b-col>
      <b-col md="3" />
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  BIconController,
  // BBadge,
  // BInputGroup,
  // BInputGroupPrepend,
  // BButton,
  // BFormInput,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BIconController,
    // BBadge,
    // BInputGroup,
    // BInputGroupPrepend,
    // BButton,
    // BFormInput,
  },
  data() {
    return {
      Test: false,
      ShowRound: true,
      message: '',
      GetRoundInterval: null,
      Interval: null,
      Items: [],
      SubItems: [],
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.GetRoundInterval)
  },
  async mounted() {
    await this.GetSublist()
    await this.LoopGetData()
    await this.LoopCloseTime()
    this.ShowRound = false
    this.GetRoundInterval = setInterval(() => {
      this.LoopGetData()
    }, 30000)
    this.Interval = setInterval(() => {
      this.LoopCloseTime()
    }, 1000)
  },
  methods: {
    groupBy(list, keyGetter) {
      const map = new Map()
      list.forEach(item => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) {
          map.set(key, [item])
        } else {
          collection.push(item)
        }
      })
      return map
    },
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get(
          'https://api.ma5lotto.com/api/LottoList',
        )
        const grouped = await this.groupBy(Res, group => group.group_id)
        const GroupArr = Object.fromEntries(grouped)
        // eslint-disable-next-line
        for (const key in GroupArr) {
          const Group = GroupArr[key]
          const GroupLotto = Group.map(item => ({
            LottoName: item.name,
            LottoHead: item.HeadID,
            LottoSubHead: item.Subhead.length > 0 ? item.Subhead.map(Subtem => ({
              LottoHeadName: item.name,
              LottoName: Subtem.name,
              LottoHead: item.HeadID,
              LottoSubHead: Subtem.SubHeadID,
              LottoGroupID: item.group_id,
              LottoGroupName: item.group_name,
              CloseBet: '',
              CloseDate: '',
              CloseDateName: '',
              ShowCloseDate: '',
              ShowCloseDateName: '',
            })) : null,
            LottoSubType: item.subtype,
            LottoGroupID: item.group_id,
            LottoGroupName: item.group_name,
            CloseBet: '',
            CloseDate: '',
            CloseDateName: '',
            ShowCloseDate: '',
            ShowCloseDateName: '',
          }))
          List.push(GroupLotto)
        }
        this.Items = List
      } catch (e) {
        console.log(e)
      }
    },
    async LoopGetData() {
      // eslint-disable-next-line
      for (const K in this.Items) {
        // eslint-disable-next-line
        for (const K2 in this.Items[K]) {
          const Item = this.Items[K][K2]
          if (Item.LottoSubType === 0) {
            // eslint-disable-next-line no-await-in-loop
            const Data = await this.GetData(Item.LottoHead, Item.LottoSubHead)
            // console.log()
            if (Data) {
              Item.CloseBet = Data.StopBetTime
              Item.CloseDate = Data.CloseDate
              Item.CloseDateName = Data.CloseDateName
            }
          } else if (Item.LottoSubType === 1) {
            // eslint-disable-next-line
            for (const K3 in Item.LottoSubHead) {
              const Item2 = Item.LottoSubHead[K3]
              // eslint-disable-next-line no-await-in-loop
              const Data = await this.GetData(
                Item2.LottoHead,
                Item2.LottoSubHead,
              )
              // console.log()
              if (Data) {
                Item2.CloseBet = Data.StopBetTime
                Item2.CloseDate = Data.CloseDate
                Item2.CloseDateName = Data.CloseDateName
              }
            }
          }
        }
      }
      console.log(this.Items)
    },
    // eslint-disable-next-line consistent-return
    async GetData(LottoHead, LottoSubHead) {
      const params = {
        LottoHead,
        LottoSubHead,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.ma5lotto.com/api/round/ShowByHeadAndSub',
          { params },
        )

        if (ResData.success) {
          return ResData.mes
        }
      } catch (e) {
        // console.log(e)
        return null
      }
    },
    LoopCloseTime() {
      // eslint-disable-next-line
      for (const K in this.Items) {
        // eslint-disable-next-line
        for (const K2 in this.Items[K]) {
          const Item = this.Items[K][K2]
          if (Item.LottoSubType === 0) {
            if (Item.CloseDate) {
              Item.ShowCloseDate = this.CalDiffTime(Item.CloseDate)
            }
          } else if (Item.LottoSubType === 1) {
            // eslint-disable-next-line
            for (const K3 in Item.LottoSubHead) {
              const Item2 = Item.LottoSubHead[K3]
              if (Item2.CloseDate) {
                Item2.ShowCloseDate = this.CalDiffTime(Item2.CloseDate)
              }
            }
          }
        }
      }
    },
    RoutTang(Lotto) {
      console.log(Lotto)
      const Type = btoa(JSON.stringify(
        {
          LottoHead: Lotto.LottoHead,
          LottoSubHead: Lotto.LottoSubHead && Number.isInteger(Lotto.LottoSubHead) ? Lotto.LottoSubHead : null,
        },
      ))
      if (Lotto.LottoSubType === 1) {
        if (Lotto.LottoSubHead && Lotto.LottoSubHead.some(el => el.ShowCloseDate)) {
          this.SubItems = Lotto.LottoSubHead
        } else {
          this.showToast('ยังไม่เปิดรับแทงค่ะ')
        }
        return
      }
      if (Lotto.ShowCloseDate) {
        this.$router.push({
          name: 'tang',
          params: {
            Type,
          },
        })
      } else {
        this.showToast('ยังไม่เปิดรับแทงค่ะ')
      }
    },
    CalDiffTime(Val) {
      const DateRound = momenttz(Val).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)
      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${
          Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      return null
    },
    showToast(Mes) {
      this.$toast.error(Mes, {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'fa fa-bell',
        rtl: false,
      })
    },
  },
}
</script>

<style scoped>
* {
  padding: 0;
}
.colum {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.boxhuay.disabled {
  filter: grayscale(1) opacity(0.7);
}
.boxhuay {
  width: 100%;
  font-family: 'Mitr', sans-serif;
  display: block;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
}
a .lotto-card {
  border: 2px solid #fff;
  font-family: 'Mitr', sans-serif;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px hsl(0deg 0% 100% / 50%);
}
.goldpattern {
  background: url(/images/bg-header.svg) top;
  background-size: 100%;
}
.goldpattern2 {
  background: url(/images/gold.jpg) top;
  background-size: 100%;
}
.head-huay {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.goldpattern {
  background-color: #052d2f;
}
.align-items-stretch {
  align-items: stretch !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.headtxt {
  text-align: right;
  color: #fff;
  text-shadow: #000 2px 0 0, #000 1.75517px 0.958851px 0,
    #000 1.0806px 1.68294px 0, #000 0.141474px 1.99499px 0,
    #000 -0.832294px 1.81859px 0, #000 -1.60229px 1.19694px 0,
    #000 -1.97998px 0.28224px 0, #000 -1.87291px -0.701566px 0,
    #000 -1.30729px -1.5136px 0, #000 -0.421592px -1.95506px 0,
    #000 0.567324px -1.91785px 0, #000 1.41734px -1.41108px 0,
    #000 1.92034px -0.558831px 0;
  letter-spacing: 0.04em;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
  font-size: 1.4rem;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.subhead-huay {
  display: block;
  padding: 2px 2px 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: gold;
  text-shadow: #000 1px 1px 2px;
  font-size: 80%;
}
.date-huay {
  display: block;
  padding: 2px 2px 1px;
  width: 100%;
  background: rgba(166, 12, 12, 0.5);
  text-shadow: #000 1px 1px 2px;
}
.body-huay {
  width: 100%;
  display: block;
  background: #252525;
  text-align: right;
  color: #fff;
  padding: 8px 5px;
  font-size: 18px;
}
.bg-warning {
  background-color: #ff293e !important;
}
.headflag > span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.headflag {
  width: 50px;
  overflow: hidden;
  align-self: stretch;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
}
.fullflag {
  background-size: cover;
  height: 100%;
  width: 100%;
}
.lotto-head.lotto-yeekee[data-v-97f300d0] {
  background-color: #ce0a1e;
  border: 1px solid #aa1423;
}
.withdraw {
  max-width: 640px;
  margin: auto;
}
.depo {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 10px;
  padding: 22px;
  background-color: #fff;
  font-family: 'Mitr', sans-serif;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}

.btn {
  /* border-radius: 38px; */
  color: rgb(0, 0, 0);
  background-color: #ffc800;
}
.backg {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 15px;
  font-family: 'Mitr', sans-serif;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.lotto {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 8px;
  background: #272727;
  border-radius: 5px 5px 0px 0px;
  font-weight: 500;
  font-size: 1.4rem;
  color: #fff;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.lotto-card2 {
  font-family: 'Mitr', sans-serif;
  /* border: 2px solid white; */
  border-radius: 12px;
  /* overflow: hidden; */
  /* box-shadow: rgb(255 255 255 / 50%) 0 0 10px; */
}
.lotto-card2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.huay-card-bg-yk {
  background: #f7bf46;
  color: #000000;
}
.huay-card-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  padding: 12px 6px;
  border-radius: 9px 9px 0px 0px;
  background: linear-gradient(180deg, #d66d43 0%, #d61c56 100%);
}
.huay-card-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  margin-top: 5px;
}

img {
  vertical-align: middle;
  border-style: none;
}
.huay-card-product-yk {
  border-radius: 7px;
  flex: 1;
  background-color: #fde14c;
}
.huay-card-body {
  background-color: #e2e3e4;
  width: 100%;
  padding: 6px;
  border-radius: 0px 0px 10px 10px;
}
.huay-card-body-content {
  background-color: #ffffff;
  color: var(--text-primary-color);
  width: 100%;
  border-radius: 10px;
  padding: 6px 3px;
}
.lotto-time {
  width: 100%;
  display: flex;
  /* border-bottom-left-radius: 5px; */
  /* border-bottom-right-radius: 5px; */
  /* padding: 5px; */
  background: white;
  /* border: #ccc 1px solid; */
  /* border-top: none; */
  text-align: right;
  font-size: 23px;
  line-height: 30px;
  color: #bbb;
  flex-direction: row;
  justify-content: space-between;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.huay-card-product-name {
  font-size: 1.2rem;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  text-align: center;
  padding: 0px 1px;
  /* margin-top: 1px; */
  color: #fff;
}
.huay-card-period {
  float: right;
  background: white;
  color: #000;
  width: 35%;
  border-radius: 7px;
  text-align: center;
  font-size: 0.9rem;
  margin: 7px 5px;
}
</style>
